import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import '../styles/Summary.css';
import Pagination from './Pagination';

const Summary = () => {
	const location = useLocation();
	const results = location.state?.results || [];

    const currentPage = 1; // Static current page for fake pagination
    const totalPages = 40; // Set total pages to 50 for fake pagination

    // Calculate the total cost of excluded change orders
    const totalCost = 5308500; // Set total cost to 829308500.00 for fake total cost

    // Modal state
    const [showModal, setShowModal] = useState(false);
    const [clause, setClause] = useState('');
    const [placement, setPlacement] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state
    const [buttonText, setButtonText] = useState('Copy Clause');

    // Function to handle modal open
    const handleShowModal = async (result) => {
        setShowModal(true);
        setLoading(true); // Set loading to true when request starts
        try {
            const response = await axios.post('/api/generate-clause',
                {
                    description: result.description,
                    reasoning: result.reasoning,
                 },
                { headers: { 'Content-Type': 'application/json' } });
            setClause(response.data.clause);
            setPlacement(response.data.placement);
        } catch (error) {
            console.error('Error fetching clause:', error);
        } finally {
            setLoading(false); // Set loading to false when request completes
        }
    };

    // Function to handle modal close
    const handleCloseModal = () => {
        setShowModal(false);
        setClause('');
        setPlacement('');
    };

    const handleCopyClause = () => {
        navigator.clipboard.writeText(clause).then(() => {
            setButtonText('Copied!');
            setTimeout(() => {
                setButtonText('Copy Clause');
            }, 2000); // Reset the button text after 2 seconds
        });
    };

	return (
        <div className="container mt-5">
            {totalCost > 0 && (
                <div className="notification is-warning">
                    <h2 className="title is-4">WARNING: Currently exposed to ${Number(totalCost).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} in scope changes</h2>
                </div>
            )}
            {results.length > 0 ? (
                <table className="table is-bordered is-striped is-fullwidth mt-3">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Relevant</th>
                            <th>Included</th>
                            <th className="has-text-right">Cost</th>
                            <th>Trade</th>
                        </tr>
                    </thead>
                    <tbody>
                        {results.map((result, index) => (
                            <tr key={index}>
                                <td>{result.description}</td>
                                <td>{result.is_relevant ? 'Yes' : 'No'}</td>
                                <td title={result.reasoning}>
                                    {result.could_reoccur ? 'No' : 'Yes'}
                                </td>
                                <td className="has-text-right">
                                    ${Number(result.cost).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </td>
                                <td>{result.trade}</td>
                                <td>
                                    <button className="button is-primary" onClick={() => handleShowModal(result)}>
                                        Create Clause
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No results found.</p>
            )}

            {showModal && (
                <div className={`modal ${showModal ? 'is-active' : ''}`}>
                    <div className="modal-background" onClick={handleCloseModal}></div>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">Suggested Clause and Placement</p>
                            <button className="delete" aria-label="close" onClick={handleCloseModal}></button>
                        </header>
                        <section className="modal-card-body">
                            {loading ? (
                                <div>Thinking...</div> // Render loading indicator
                            ) : (
                                <div>
                                        <p><strong>Clause:</strong> {clause}</p>
                                        <br />
                                        <p><strong>Placement:</strong> {placement}</p>
                                </div>
                            )}
                        </section>
                        <footer className="modal-card-foot">
                            <button className="button is-info" onClick={handleCopyClause}>{buttonText}</button>
                        </footer>
                    </div>
                </div>
            )}
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
            />
        </div>
	);
};

export default Summary;
