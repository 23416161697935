import React, { createContext, useState, useContext } from 'react';

// Create the context
export const ResultsContext = createContext();

// Create a provider component
export const ResultsProvider = ({ children }) => {
	const [results, setResults] = useState(null);

	return (
		<ResultsContext.Provider value={{ results, setResults }}>
			{children}
		</ResultsContext.Provider>
	);
};

// Create and export the useResults hook
export const useResults = () => {
	const context = useContext(ResultsContext);
	if (context === undefined) {
		throw new Error('useResults must be used within a ResultsProvider');
	}
	return context;
};