import React from 'react';
import 'bulma/css/bulma.min.css'; // Ensure Bulma CSS is imported

const Pagination = ({ currentPage, totalPages }) => {
	const getPageNumbers = () => {
		const pageNumbers = [];
		const maxPagesToShow = 5; // Adjust as needed
		const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

		if (totalPages <= maxPagesToShow) {
			for (let i = 1; i <= totalPages; i++) {
				pageNumbers.push(i);
			}
		} else {
			pageNumbers.push(1);
			if (currentPage > halfMaxPagesToShow + 2) {
				pageNumbers.push('...');
			}

			const startPage = Math.max(2, currentPage - halfMaxPagesToShow);
			const endPage = Math.min(totalPages - 1, currentPage + halfMaxPagesToShow);

			for (let i = startPage; i <= endPage; i++) {
				pageNumbers.push(i);
			}

			if (currentPage < totalPages - halfMaxPagesToShow - 1) {
				pageNumbers.push('...');
			}
			pageNumbers.push(totalPages);
		}

		return pageNumbers;
	};

	const pageNumbers = getPageNumbers();

	return (
		<nav className="pagination is-centered" role="navigation" aria-label="pagination" style={{ marginBottom: '2rem' }}>
			<button
				className="pagination-previous"
				disabled={currentPage === 1}
			>
				Previous
			</button>
			<button
				className="pagination-next"
				disabled={currentPage === totalPages}
			>
				Next
			</button>
			<ul className="pagination-list">
				{pageNumbers.map((page, index) => (
					<li key={index}>
						{page === '...' ? (
							<span className="pagination-ellipsis">&hellip;</span>
						) : (
							<button
								className={`pagination-link ${page === currentPage ? 'is-current' : ''}`}
								aria-label={`Goto page ${page}`}
							>
								{page}
							</button>
						)}
					</li>
				))}
			</ul>
		</nav>
	);
};

export default Pagination;