import React, { useState } from 'react';
import 'bulma/css/bulma.min.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';
import Pagination from './Pagination';

const FileList = () => {
	const [selectedFile, setSelectedFile] = useState(null);

    const currentPage = 1; // Static current page for fake pagination
    const totalPages = 40; // Set total pages to 50 for fake pagination

    const files = [
        "990Berry_LEED_Tstats.pdf",
        "CO1_2_990Berry_Mech_Mill.docx",
        "704Nassau_fans_gasmeter.jpg",
        "MECH_acoustic_704Nassau.xlsx",
        "704NassauAve_Elec_upgrade_5.png",
        "500Jackson_steam_lobby.txt",
        "CO7_Plumb_WaterSoft.pdf",
        "500JAve_BMS_integ.doc",
        "50-80Union_FireStop_9.xlsx",
        "Mech_Humidity_50-80U.png",
        "CO11_12_HVAC_Reloc_ServerRm.pdf",
        "990B_704N_DoorBucks_Compressor.docx",
        "Mech_Elec_704Nassau_3_4_5.txt",
        "500J_Steam_WaterSoft_6_7.jpg",
        "BMS_Integration_8.xlsx",
        "50-80_Union_HVAC_9_10_11.png",
        "Berry_Nassau_Jackson_CO1-8.pdf",
        "Union_St_Mechanical_Work.doc",
        "HVAC_Telecom_12.txt",
        "CO1-12_AllProjects_MechElecPlumb.xlsx",
        "CO_13_14_Rewire_and_Shelving.pdf",
        "42ParkAve_HVAC_ventilation.docx",
        "198Lex_Plumb&Mason.txt",
        "75-5th-ave-paint.jpg",
        "CO19_20_221Madison.xlsx",
        "89_7th_Roof_Elec.png",
        "154W42_HVAC-Plumb.pdf",
        "25-26_Carp_HVAC_267E86.doc",
        "33WallSt_masonry-painting.pdf",
        "112CanalSt_HVAC_heat_pump.txt",
        "CO30_31_Flooring_Roofing.docx",
        "127Brdwy_HVAC_ductwork.jpg",
        "42ParkAve_Electrical_33.png",
        "198LEX_PLUMB_TOILET.xlsx",
        "75_5th_Ave_HVAC_repair.pdf",
        "221Mad_Carp_36.doc",
        "89-7th-Ave-HVAC-thermostat.txt",
        "154W42_Mason_38.jpg",
        "267e86_HVAC_vent_39.png",
        "33WallSt_paint_ceiling.xlsx",
        "112Canal_AC_unit_41.pdf",
        "CO42_43_Flooring_CoolingTower.docx",
        "198Lex_Roof_44.txt",
        "75-5th-HVAC-smart-AC.jpg",
        "221MadisonAve_Elec_46.png",
        "89_7th_HVAC_vent_recovery.xlsx",
        "154W42_Plumb_48.pdf",
        "267E86_HVAC_BMS_49.doc",
        "33WallSt_Carp_partition_50.txt"
	];

	const handleFileClick = (file) => {
		setSelectedFile(file);
	};

	return (
		<div className="container mt-5">
			<h1 className="title">Change Order Files</h1>
			<div className="box">
				{files.map((file, index) => (
					<div
						key={index}
						className={`notification ${selectedFile === file ? 'is-primary' : ''}`}
						onClick={() => handleFileClick(file)}
						style={{ 
							cursor: 'pointer', 
							display: 'flex', 
							alignItems: 'center', 
							padding: '0.5rem 1rem', // Adjust padding to make the cards shorter
							marginBottom: '0.5rem' // Adjust margin to control spacing between cards
						}}
					>
						{/* <span className="icon mr-2">
							<i className="fas fa-file-alt"></i>
						</span> */}
						{file}
					</div>
				))}
			</div>
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
            />
		</div>
	);
};

export default FileList;