import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bulma/css/bulma.min.css'; // Import Bulma CSS
import FileUpload from './components/FileUpload';
import Results from './components/Results';
import Summary from './components/Summary';
import LoadingPage from './components/LoadingPage';
import COs from './components/COs';
import FileList from './components/FileList';
import Layout from './components/Layout';
import { ResultsProvider } from './context/ResultsContext'; // Import ResultsProvider

const App = () => {
	return (
		<ResultsProvider>
			<Router>
				<Layout>
					<Routes>
						<Route path="/" element={<FileUpload />} />
						<Route path="/results" element={<Results />} />
						<Route path="/summary" element={<Summary />} />
						<Route path="/loading" element={<LoadingPage />} />
						<Route path="/cos" element={<COs />} />
						<Route path="/files" element={<FileList />} />
					</Routes>
				</Layout>
			</Router>
		</ResultsProvider>

	);
	
};

export default App;