import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useResults } from '../context/ResultsContext';
import 'bulma/css/bulma.min.css';
import '../styles/Results.css';
import Pagination from './Pagination';

const Results = () => {
    const navigate = useNavigate();
    const { results } = useResults();

    const currentPage = 1; // Static current page for fake pagination
    const totalPages = 46; // Set total pages to 50 for fake pagination

    if (!results) {
        return <div className="container mt-5">No analysis results to display.</div>;
    }

    const handleSummarize = () => {
        const filteredResults = results.filter(result => result.is_relevant & result.could_reoccur);
        navigate('/summary', { state: { results: filteredResults } });
    };

    return (
        <div className="container mt-5">
            <div className="columns is-vcentered is-mobile">
                <div className="column is-8">
                    <h1 className="title">COs and Scope Checklist Items</h1>
                </div>
                <div className="column is-4 has-text-right">
                    <button className="button is-primary" onClick={handleSummarize}>
                        Summarize
                    </button>
                </div>
            </div>
            {results.length > 0 ? (
                <div className="table-container mt-3">
                    <table className="table is-bordered is-striped is-fullwidth">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Relevant</th>
                                <th>Included</th>
                                <th className="has-text-right">Cost</th>
                                <th>Trade</th>
                            </tr>
                        </thead>
                        <tbody>
                            {results.map((result, index) => (
                                <tr key={index}>
                                    <td>{result.description}</td>
                                    <td>{result.is_relevant ? 'Yes' : 'No'}</td>
                                    <td title={result.is_relevant ? result.reasoning : ''}>
                                        {result.is_relevant ? (result.could_reoccur ? 'No' : 'Yes'): 'NA'}
                                    </td>
                                    <td className="has-text-right">
                                        ${Number(result.cost).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </td>
                                    <td>{result.trade}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                    />
                </div>
            ) : (
                <p className="has-text-centered">No results found.</p>
            )}
        </div>
    );
};

export default Results;