import React, { useState, useRef, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'bulma/css/bulma.min.css';
import { ResultsContext } from '../context/ResultsContext'; // Import ResultsContext

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const { setResults } = useContext(ResultsContext); // Get setResults from context

  const handleDrop = (event) => {
	event.preventDefault();
	const droppedFile = event.dataTransfer.files[0];
	setFile(droppedFile);
	setError(null);
  };

  const handleDragOver = (event) => {
	event.preventDefault();
  };

  const handleFileChange = (event) => {
	const selectedFile = event.target.files[0];
	setFile(selectedFile);
	setError(null);
  };

  const handleUpload = async () => {
	if (!file) {
	  setError('No file selected');
	  return;
	}

	// Redirect to loading page
	navigate('/loading');

	const formData = new FormData();
	formData.append('file', file);

	try {
	  const response = await axios.post('/upload', formData, {
		headers: {
		  'Content-Type': 'multipart/form-data',
		},
	  });
	  console.log(response.data.results);
	  setResults(response.data.results); // Set results in context
	  navigate('/results');
	  setError(null);
	} catch (error) {
	  console.error('Error uploading file:', error);
	  setError(error.message);
	}
  };

  return (
	<div className="container">
	  <div className="section">
		<div className="box">
		  <div
			className="file-dropzone"
			onDrop={handleDrop}
			onDragOver={handleDragOver}
			onClick={() => fileInputRef.current.click()}
			style={{
			  border: '2px dashed #00d1b2',
			  padding: '20px',
			  textAlign: 'center',
			  cursor: 'pointer',
			  width: '300px', // Adjust width
			  height: '300px', // Adjust height
			  margin: '0 auto', // Center the box
			  display: 'flex',
			  alignItems: 'center',
			  justifyContent: 'center',
			  borderRadius: '10px', // Rounded corners
			  backgroundColor: '#d3d3d3', // Light grey background color
			}}
		  >
			{file ? (
			  <p className="has-text-success">{file.name}</p>
			) : (
			  <p className="has-text-grey">Drag and drop a file here, or click to select a file</p>
			)}
		  </div>
		  <input
			type="file"
			ref={fileInputRef}
			style={{ display: 'none' }}
			onChange={handleFileChange}
		  />
		  {error && <p className="has-text-danger has-text-centered">{error}</p>}
		  <div className="field is-grouped is-grouped-centered" style={{ marginTop: '20px' }}>
			<p className="control">
			  <button className="button is-primary" onClick={handleUpload}>
				Upload
			  </button>
			</p>
		  </div>
		</div>
	  </div>
	</div>
  );
};

export default FileUpload;