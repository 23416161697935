import React from 'react';
import 'bulma/css/bulma.min.css';
import '../styles/LoadingPage.css'; // Import the CSS file

const LoadingPage = () => {
  return (
	<div className="hero is-fullheight">
	  <div className="hero-body">
		<div className="container has-text-centered">
		  <div className="box loading-box">
			<img src="/loading-thinking.gif" alt="Loading..." className="loading-image" />
			<h2 className="title is-4 loading-text">Processing your upload, please wait...</h2>
		  </div>
		</div>
	  </div>
	</div>
  );
};

export default LoadingPage;