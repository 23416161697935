import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Pagination from './Pagination';

const COs = () => {
	const [cos, setCos] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

    const currentPage = 1; // Static current page for fake pagination
    const totalPages = 46; // Set total pages to 50 for fake pagination

	useEffect(() => {
		axios.get('/api/cos')
			.then(response => {
				setCos(response.data);
				setLoading(false);
			})
			.catch(error => {
				setError('There was an error fetching the COs!');
				setLoading(false);
			});
	}, []);

	if (loading) {
		return <div className="container mt-5"><p>Loading...</p></div>;
	}

	if (error) {
		return <div className="container mt-5"><p className="has-text-danger">{error}</p></div>;
	}

	return (
		<div className="container mt-5">
			<h1 className="title">Change Orders</h1>
			<div className="table-container">
				<table className="table is-striped is-hoverable is-fullwidth">
					<thead>
						<tr>
							<th>ID</th>
							<th>Project</th>
							<th>Trade</th>
							<th className="has-text-right">Cost</th>
							<th>Description</th>
						</tr>
					</thead>
					<tbody>
						{cos.map((co, index) => (
							<tr key={index}>
								<td>{co.id}</td>
								<td>{co.project}</td>
								<td>{co.trade}</td>
								<td className="has-text-right">
									${Number(co.cost).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
								</td>
								<td>{co.description}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
            />
		</div>
	);
};

export default COs;